import axios from 'axios';
import _ from 'lodash';

export default {
  methods: {
    increaseQuantity(item) {
      return new Promise((resolve, reject) => {

        const url = _.isArray(item) ? 'api/v1/cart/list' : 'api/v1/cart';

        axios.post(url, item)
          .then((res) => {
            const payload = _.get(res, 'data.data', []);

            return this.$store.dispatch("cart/updateCart", payload);
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {

            reject(err);
          });
      });
    },
    decreaseQuantity(item) {
      return new Promise((resolve, reject) => {
        axios.put('api/v1/cart', item)
          .then((res) => {
            const payload = _.get(res, 'data.data', []);

            return this.$store.dispatch("cart/updateCart", payload);
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {

            reject(err);
          });
      });
    },
    removeItem(item){
      return new Promise((resolve, reject) => {
        axios.delete(`api/v1/cart/${item.ocDtlId}`)
          .then((res) => {
            const payload = _.get(res, 'data.data', []);
            
            return this.$store.dispatch("cart/updateCart", payload);
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {

            reject(err);
          });
      });
    },
    addToWishlist(product){
      return new Promise((resolve, reject) => {
        
        axios.post('api/v1/wishlist', {
          tblLgtProducto: product
        })
          .then((res) => {
            const wishlist = _.get(res, 'data.data', []);

            resolve(wishlist);
          })
          .catch(() => {
            reject();
          });

      });
    },
  }
}