<template>
  <v-expansion-panels
    class="pa-4"
    :value="groupedStores.map((i, index) => index)"
    :mandatory="false"
    multiple
  >
    <v-expansion-panel v-for="(store, i) in groupedStores" :key="i">
      <v-expansion-panel-header disable-icon-rotate>
        <div class="caption text-uppercase font-weight-bold">{{ `(${store.items.length}) ${store.comercio}` }}</div>
        <template #actions>
          <v-icon color="primary">mdi-cart-outline</v-icon>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-list dense>
          <v-list-item v-for="(item, index) in store.items" :key="index">
            <v-list-item-action>
                <v-btn-toggle dense class="ma-0">
                  <v-btn color="primary" outlined x-small :disabled="disabled || item.esAgendable=='Y'" @click="() => decreaseProductQuantity(item)">
                    <v-icon x-small>mdi-minus</v-icon>
                  </v-btn>

                  <v-btn x-small class="caption">{{ item.cantidadCaja || 0 }}</v-btn>

                  <v-btn color="primary" x-small outlined :disabled="disabled || item.esAgendable=='Y'" @click="() => increaseProductQuantity(item)">
                    <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </v-btn-toggle>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ item.producto }}</v-list-item-title>
              <v-list-item-subtitle>$ {{ ((item.precioNeto || 0) * (item.cantidadCaja || 0)).toFixed(2) }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.fechaApt"><v-icon x-small class="pr-1">mdi-calendar</v-icon>{{ item.fechaApt }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-btn
                  icon
                  @click="() => removeProductFromCart(item, index)"
                  :loading="loading == index"
                >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-btn
          block
          color="primary"
          @click="() => $router.push(`/entities/${store.entidadId}/commerce/${store.comercioId}/checkout`).catch(() => {})"
          :disabled="disabled"
        >{{ `${$t("AppBar.checkout")} ($ ${(store.total || 0).toFixed(2)})` }}</v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import ProductMixins from '../../mixins/ProductMixins';

export default {
  mixins: [
    ProductMixins
  ],
  computed: {
    ...mapState({
      items: (state) => state.cart.items,
    }),
    itemsCount() {
      return this.items.length;
    },
    groupedStores() {

      const groupByCategory = this.items.reduce((group, product) => {
        const tblCnfComercio = product.tblCnfComercio;
        const comercioId = tblCnfComercio.comercioId;
        const comercio = tblCnfComercio.comercio;
        const entidadId = _.get(product, 'tblEntEntidad.entidadId');

        group[comercioId] = group[comercioId] ?? {
          comercioId,
          comercio,
          entidadId,
          items: []
        };
        
        group[comercioId].items.push(product);
        
        return group;
      }, {});

      return Object.entries(groupByCategory).map(([keys, values])=>{
        let total = _.sumBy(values.items, (i) => (i.precioNeto || 0) * (i.cantidadCaja || 0));

        values.total = total;

        return values;
      });
    },
  },
  data() {
    return {
      disabled: false,
      loading: null,
    };
  },
  methods: {
    increaseProductQuantity(item) {

      this.disabled = true;

      this.increaseQuantity(item)
        .then(() => {
          // DOES NOTHING
        })
        .catch(() => {
          // DOES NOTHING
        })
        .finally(() => {
          this.disabled = false;
        });
     
    },
    decreaseProductQuantity(item) {
      
      this.disabled = true;

      this.decreaseQuantity(item)
        .then(() => {
          // DOES NOTHING
        })
        .catch(() => {
          // DOES NOTHING
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    removeProductFromCart(item, index) {
      
      this.disabled = true;

      this.loading = index;

      this.removeItem(item)
        .then(() => {
          // DOES NOTHING
        })
        .catch(() => {
          // DOES NOTHING
        })
        .finally(() => {
          this.disabled = false;

          this.loading = null;
        });
    },
  },
};
</script>