<template>
  <v-container class="my-6">
    <v-row>
      <v-col cols="12">
        <shopping-list></shopping-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShoppingList from './ShoppingList';

export default {
  components: {
    ShoppingList
  },
};
</script>